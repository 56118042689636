import React from 'react'

function Confirmation({ type, okText, onCancel, onOk, title, description }) {
    return (
        <section className='fixed inset-0 grid place-items-center z-40'>
            <div className='bg-black bg-opacity-70 fixed inset-0' onClick={onCancel}></div>

            <div className='bg-gray4 rounded-lg w-full max-w-xl pb-5 pt-10 px-8 flex flex-col items-center justify-center relative'>
                <svg width="150" height="180" viewBox="0 0 158 201" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M55.8533 153.509C79.8656 153.509 99.3315 134.043 99.3315 110.031C99.3315 86.0186 79.8656 66.5527 55.8533 66.5527C31.8409 66.5527 12.375 86.0186 12.375 110.031C12.375 134.043 31.8409 153.509 55.8533 153.509Z" fill="#FF8B66" />
                    <path d="M70.0935 189.652C94.1059 189.652 113.572 170.186 113.572 146.174C113.572 122.161 94.1059 102.695 70.0935 102.695C46.0811 102.695 26.6152 122.161 26.6152 146.174C26.6152 170.186 46.0811 189.652 70.0935 189.652Z" fill="#FF8B66" />
                    <path d="M97.2005 164.379C115.21 164.379 129.809 149.78 129.809 131.771C129.809 113.762 115.21 99.1621 97.2005 99.1621C79.1912 99.1621 64.5918 113.762 64.5918 131.771C64.5918 149.78 79.1912 164.379 97.2005 164.379Z" fill="#FF8B66" />
                    <path d="M153.68 99.1622C153.68 153.19 119.614 196.988 77.5927 196.988C35.5712 196.988 1.50586 153.19 1.50586 99.1622C1.50586 45.134 35.5712 1.33594 77.5927 1.33594C119.614 1.33594 153.68 45.134 153.68 99.1622ZM20.2596 99.1622C20.2596 139.873 45.9288 172.876 77.5927 172.876C109.257 172.876 134.926 139.873 134.926 99.1622C134.926 58.4509 109.257 25.448 77.5927 25.448C45.9288 25.448 20.2596 58.4509 20.2596 99.1622Z" fill="#FF7732" />
                    <path d="M70.0142 117.443C70.5529 117.443 71.0695 117.657 71.4505 118.038C71.8314 118.419 72.0454 118.936 72.0454 119.475V133.693C72.0454 134.232 71.8314 134.749 71.4505 135.13C71.0695 135.511 70.5529 135.725 70.0142 135.725C69.4754 135.725 68.9588 135.511 68.5778 135.13C68.1969 134.749 67.9829 134.232 67.9829 133.693V119.475C67.9829 118.936 68.1969 118.419 68.5778 118.038C68.9588 117.657 69.4754 117.443 70.0142 117.443ZM66.9673 142.834C66.9673 143.437 67.146 144.026 67.4808 144.527C67.8156 145.028 68.2914 145.418 68.8482 145.649C69.4049 145.88 70.0175 145.94 70.6086 145.822C71.1996 145.705 71.7425 145.415 72.1686 144.989C72.5947 144.562 72.8849 144.02 73.0025 143.428C73.1201 142.837 73.0597 142.225 72.8291 141.668C72.5985 141.111 72.208 140.635 71.7069 140.301C71.2059 139.966 70.6168 139.787 70.0142 139.787C69.2061 139.787 68.4311 140.108 67.8597 140.68C67.2883 141.251 66.9673 142.026 66.9673 142.834ZM98.4517 131.662C98.4532 132.194 98.3491 132.721 98.1456 133.213C97.9421 133.705 97.6431 134.151 97.2659 134.526L72.8782 158.917C72.1171 159.673 71.0874 160.098 70.0142 160.098C68.9409 160.098 67.9113 159.673 67.1501 158.917L42.7751 134.526C42.0185 133.765 41.5938 132.735 41.5938 131.662C41.5938 130.589 42.0185 129.559 42.7751 128.798L67.1628 104.408C67.924 103.651 68.9536 103.227 70.0269 103.227C71.1001 103.227 72.1298 103.651 72.8909 104.408L97.2786 128.798C97.6535 129.174 97.9501 129.621 98.1515 130.113C98.3528 130.604 98.4548 131.131 98.4517 131.662ZM94.3892 131.662L70.0142 107.287L45.6392 131.662L70.0142 156.037L94.3892 131.662Z" fill="#242424" />
                </svg>
                <h1 className='text-[#FF7D3A] text-center text-2xl'>{title}</h1>
                <p className='text-[#FF7D3A] font-light text-center'>{description}</p>
                <div className='w-full flex justify-between mt-10'>
                    <button className={`py-1.5 px-6 rounded-full ${type == 'update' ? 'bg-[#B26138]' : 'bg-[#3E7135]'}`} onClick={onCancel}>Cancel</button>
                    <button className={`py-1.5 px-6 rounded-full ${type == 'update' ? 'bg-[#3E7135]' : 'bg-[#713535]'}`} onClick={onOk}>{okText || 'Continue'}</button>
                </div>
            </div>
        </section>
    )
}

export default Confirmation