import { endpoints } from './config';
import HttpService from './axios-interceptor';

const validate = ({ email, employeeId }) => {
    return HttpService.get(`/branch` + endpoints.validate + `?email=${email?.trim().toLowerCase()}&employeeId=${employeeId?.trim().toLowerCase()}`);
};

const getEmployees = (type) => {
    return HttpService.get(`/branch` + endpoints.employees + `?type=${type}`);
};

const getEmployee = (employeeId) => {
    return HttpService.get(`/branch` + endpoints.employee + `?employeeId=${employeeId}`);
};

const getSectorVisit = (name) => {
    if (name)
        return HttpService.get(`/branch` + endpoints.sectorVisits + `?name=${name}`);
};

const validateScanner = (deviceId, deviceName) => {
    return HttpService.get(`/branch` + endpoints.validate + `/scanner?deviceId=${deviceId}&deviceName=${deviceName}`);
};

const register = data => {
    return HttpService.patch(
        "/branch" + endpoints.register,
        data,
    );
};

const removeEmpFromSector = data => {
    return HttpService.post(
        "/branch" + endpoints.removeEmpFromSector,
        data,
    );
};
const addEmployees = data => {
    return HttpService.post(
        "/branch" + endpoints.employees,
        data,
    );
};

const updateEmployeeSector = data => {
    return HttpService.patch(
        "/branch" + endpoints.employee + endpoints.sector,
        data,
    );
};

const addSector = data => {
    return HttpService.post(
        "/branch" + endpoints.sector,
        data,
    );
};

const addScanner = data => {
    return HttpService.post(
        "/branch" + endpoints.scanner,
        data,
    );
};
const handleScanner = data => {
    return HttpService.patch(
        "/branch" + endpoints.scanner,
        data,
    );
};
const removeScanner = ({ id }) => {
    if (id)
        return HttpService.delete(
            "/branch" + endpoints.scanner + `?id=${id}`
        );
};

const getSectors = () => {
    return HttpService.get(
        "/branch" + endpoints.sector + "s",
    );
};
const getSectorsExtended = () => {
    return HttpService.get(
        "/branch" + endpoints.sector + "s/extended",
    );
};

const getSector = (sectorId) => {
    if (sectorId)
        return HttpService.get(
            "/branch" + endpoints.sector + `?sectorId=${sectorId}`,
        );
};

const getDevice = (deviceId) => {
    return HttpService.post(
        "/branch" + endpoints.device,
        { deviceId }
    );
};

const employeeSectorVisit = (employeeId) => {
    return HttpService.get(
        "/branch" + endpoints.employee + `/sectorVisit?employeeId=${employeeId}`,
    );
};

const updateSector = (data) => {
    return HttpService.patch(
        "/branch" + endpoints.sector,
        data
    );
};
const accessRequest = (employeeId) => {
    return HttpService.get(
        "/branch" + endpoints.accessRequest + `?employeeId=${employeeId}`,
    );
};
const pastRequest = (employeeId) => {
    return HttpService.get(
        "/branch" + endpoints.pastRequest + `?employeeId=${employeeId}`,
    );
};

const getTempRequest = () => {
    return HttpService.get(
        "/branch" + endpoints.tempRequest,
    );
};
const getActiveRequest = () => {
    return HttpService.get(
        "/branch" + endpoints.activeRequest,
    );
};

const updateAccessRequest = (data) => {
    return HttpService.patch(
        "/branch" + endpoints.accessRequest,
        data
    );
};

const getVisitorRequest = () => {
    return HttpService.get(
        "/branch" + endpoints.visitorRequest,
    );
};
const getTodayVisitor = () => {
    return HttpService.get(
        "/branch" + endpoints.visitorRequest + '/today',
    );
};

const updateVisitorRequest = (data) => {
    return HttpService.patch(
        "/branch" + endpoints.visitorRequest,
        data
    );
};


const visitorSeen = () => HttpService.get('/branch' + endpoints.visitorRequest + '/seen')
const tempRequestSeen = () => HttpService.get('/branch' + endpoints.tempRequest + '/seen')
const addVisitor = (data) => HttpService.post('/branch' + '/request/visitor', data)
const addTempRequest = (data) => HttpService.post('/branch' + endpoints.tempRequest, data)

const eqInsight = (employeeId) => employeeId ? HttpService.get('/branch' + endpoints.eqInsight + `?employeeId=${employeeId}`) : {}
const deniedAccess = (employeeId) => employeeId ? HttpService.get('/branch' + endpoints.deniedAccess + `?employeeId=${employeeId}`) : {}
const sqInsight = () => HttpService.get('/branch' + endpoints.sqInsight)

export const BranchService = {
    validate,
    register,
    addEmployees,
    getEmployees,
    getEmployee,
    updateEmployeeSector,
    addSector,
    getSectors,
    getSector,
    updateSector,
    validateScanner,
    getDevice,
    addScanner,
    employeeSectorVisit,
    handleScanner,
    removeScanner,
    getTempRequest,
    updateAccessRequest,
    accessRequest,
    pastRequest,
    getSectorVisit,
    getSectorsExtended,
    removeEmpFromSector,
    getActiveRequest,
    getVisitorRequest,
    updateVisitorRequest,
    getTodayVisitor,
    visitorSeen,
    tempRequestSeen,
    addVisitor,
    eqInsight,
    sqInsight,
    deniedAccess,
    addTempRequest
};