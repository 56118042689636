import { useEffect } from "react"
import { toast } from "react-hot-toast"
import { QueryClient, useMutation, useQuery, useQueryClient } from "react-query"
import { useDispatch } from "react-redux"
import { AdminService } from "../services/admin.service"
import { setAdminBranches, setBranchAdmins, setCompany, setUserInfo, setUserSectors } from "../slices/userSlice"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { BranchService } from "../services/branch.service"

export const useInitialLoad = () => {
    const history = useHistory();
    const dispatch = useDispatch();

    let loc = window.location.hostname?.split(".")
    let subdomain = loc[0]

    let { isLoading: loading, data: companyData, refetch: validateCompany } = useQuery('validateCompany', () => AdminService.getCompany(subdomain), {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => {
            if (res?.success && !res?.data) {
                if (loc[1] == 'compadmin') {
                    history.push('/branches')
                }
            }
        },
        onError: (e) => console.log(e)
    })

    useEffect(() => {
        if (loc[1] == 'compadmin') {
            validateCompany()
        }
    }, [subdomain])


    let { isLoading, data, isError, error, isFetching, refetch: validateToken } = useQuery('validateToken', AdminService.auth, {
        // enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onError: res => {
            history.push("/")
        }
    })

    const { refetch } = useQuery('getSectors', BranchService.getSectors, {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => { dispatch(setUserSectors(res?.data)) },
        onError: (er) => console.log(er)
    })

    useEffect(() => {
        if (companyData?.data) {
            // validateToken();
            dispatch(setCompany(companyData?.data));
        }
    }, [companyData])

    const { refetch: getBranchAdmin } = useQuery('getBranchAdmin', AdminService.getBranchAdmin, {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        onSuccess: res => {
            dispatch(setBranchAdmins(res?.data || []))
        }
    })

    useEffect(() => {
        if (data?.data) {
            refetch();
            dispatch(setUserInfo(data?.data));
            if (!data?.data?.logo && data?.data?.role === 'admin') {
                history.push('/add-logo')
            }
            else {
                if (data.data?.branch) {
                    history.push(`/branch/${data?.data?.branch}/employees`)
                } else {
                    getBranchAdmin();
                    history.push('/branches');
                }
            }
        }
    }, [data])

    return { isLoading: isLoading || loading, isError, error, isFetching }
}

export const useSendOtp = (callback) => {
    return useMutation(AdminService.sendOtp, {
        onSuccess: (response) => {
            if (response?.success === true) {
                console.log("OTP ", response.data.otp)
                toast?.success("OTP sent successfully!!", { id: 'success' });
                if (typeof callback === 'function') {
                    callback()
                }
            } else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to send OTP", { id: 'error' })
        }
    })
}

export const useVerifyOtp = (callback) => {
    return useMutation(AdminService.verifyOtp, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("OTP verified successfully!!", { id: 'success' });
                if (typeof callback == 'function')
                    callback(response.data);
            } else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to send OTP", { id: 'error' })
        }
    })
}

export const useLogin = (callback) => {
    return useMutation(AdminService.login, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (response.isActive) {
                    localStorage.setItem('aqqess_token', response.accessToken);
                    window.location.replace('/')
                    // window.location.replace(`${window.location.protocol}//${response.subdomain}.${window.location.host}/`)
                }
                else
                    toast("Please verify your email", { id: 'info' })
                if (typeof callback == 'function')
                    callback();
            } else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Unable to login user", { id: 'error' })
        }
    })
}

export const useRegisterCompany = (callback) => {
    let { mutate } = useSendOtp()
    return useMutation(AdminService.register, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("Company Registered successfully!!", { id: 'success' });
                if (response.data.email)
                    mutate({ email: response.data.email, type: 'admin', flow: 'register' })
                if (typeof callback === 'function')
                    callback();
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            toast.error("Oops! Unable to register user", { id: 'error' })
        }
    })
}

export const useAddCompanyInformation = (callback) => {
    return useMutation(AdminService.updateCompany, {

        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("Company Info Updated Successfully!", { id: 'success' });
                if (typeof callback === 'function')
                    callback();
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useUpdateProfile = (callback) => {
    return useMutation(AdminService.updateProfile, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("Profile Updated Successfully!", { id: 'success' });
                if (typeof callback == 'function')
                    callback();
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useResetPassword = (callback) => {
    return useMutation(AdminService.resetPassword, {
        onSuccess: (response) => {
            toast?.success("Password Updated Successfully!", { id: 'success' })
            if (response?.success === true) {
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useUpdatePassword = (callback) => {
    return useMutation(AdminService.updatePassword, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast?.success("Password Updated Successfully!", { id: 'success' })
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useRegister = (callback) => {
    let { mutate } = useSendOtp()
    return useMutation(BranchService.register, {
        onSuccess: (response) => {
            if (response?.success === true) {

                if (!response.isActive) {
                    mutate({ email: response?.data?.email, type: 'branch', flow: 'register' })
                }
                else
                    toast.success("Registered Successfully!", { id: 'success' })
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useAddEmployees = (callback) => {
    let queryClient = useQueryClient()
    return useMutation(BranchService.addEmployees, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getEmployees')
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useUpdateEmployeeSector = (callback) => {
    return useMutation(BranchService.updateEmployeeSector, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast.success("Employee Sectors Updated")
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useAddSector = (callback) => {
    const dispatch = useDispatch()
    const { refetch } = useQuery('getSectors', BranchService.getSectors, {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => { dispatch(setUserSectors(res?.data)) },
        onError: (er) => console.log(er)
    })

    return useMutation(BranchService.addSector, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast.success("Sector Added Successfully");
                refetch();
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useUpdateSector = (callback) => {
    const dispatch = useDispatch()
    const { refetch } = useQuery('getSectors', BranchService.getSectors, {
        enabled: false,
        retry: false,
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        onSuccess: res => { dispatch(setUserSectors(res?.data)) },
        onError: (er) => console.log(er)
    })
    const queryClient = useQueryClient()
    return useMutation(BranchService.updateSector, {
        onSuccess: (response) => {
            if (response?.success === true) {
                toast.success("Sector Updated Successfully");
                refetch();
                queryClient.invalidateQueries('getSectorsExtended')
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useAddScanner = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(BranchService.addScanner, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getSectors')
                if (typeof callback === 'function')
                    callback(response);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useHandleScanner = (callback) => {
    return useMutation(BranchService.handleScanner, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useRemoveScanner = (callback) => {
    const queryClient = useQueryClient();
    return useMutation(BranchService.removeScanner, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getSectorsExtended')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useRemoveEmpFromSector = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(BranchService.removeEmpFromSector, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getSectorsExtended')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useUpdateAccessRequest = (callback) => {
    return useMutation(BranchService.updateAccessRequest, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (response?.data) {
                    if (response?.data?.active == 1) {
                        toast.success("Request Accepted", { id: 'success' })
                    }
                    else if (response?.data?.active == 2) {
                        toast.success("Request Rejected", { id: 'success' })
                    }
                    if (typeof callback === 'function')
                        callback(response?.data);
                }
                else
                    toast.error('Unable to update', { id: 'error' })
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useUpdateVisitorRequest = (callback) => {
    return useMutation(BranchService.updateVisitorRequest, {
        onSuccess: (response) => {
            if (response?.success === true) {
                if (response?.data) {
                    if (response?.data?.active == 1) {
                        toast.success("Request Accepted", { id: 'success' })
                    }
                    else if (response?.data?.active == 2) {
                        toast.success("Request Rejected", { id: 'success' })
                    }
                    if (typeof callback === 'function')
                        callback(response?.data);
                }
                else
                    toast.error('Unable to update', { id: 'error' })
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}


export const useAddVisitor = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(BranchService.addVisitor, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getVisitorRequest')
                if (typeof callback === 'function')
                    callback(response?.data?.link);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useAddTempRequest = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(BranchService.addTempRequest, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getTempRequest')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useManageBranchAdmin = (type, callback) => {
    const queryClient = useQueryClient()
    return useMutation(type == "edit" ? AdminService.editBranchAdmin : AdminService.createBranchAdmin, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranchAdmin')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useAddBranchAdmin = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(AdminService.createBranchAdmin, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranchAdmin')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useEditBranchAdmin = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(AdminService.editBranchAdmin, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranchAdmin')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}
export const useDeleteBranchAdmin = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(AdminService.deleteBranchAdmin, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranchAdmin')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useManageBranch = (type, callback) => {
    const queryClient = useQueryClient()
    return useMutation(type == "edit" ? AdminService.editBranch : AdminService.createBranch, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranches')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useAddBranch = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(AdminService.createBranch, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranches')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useEditBranch = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(AdminService.editBranch, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranches')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}

export const useDeleteBranch = (callback) => {
    const queryClient = useQueryClient()
    return useMutation(AdminService.deleteBranch, {
        onSuccess: (response) => {
            if (response?.success === true) {
                queryClient.invalidateQueries('getBranches')
                if (typeof callback === 'function')
                    callback(response?.data);
            }
            else if (response?.message) {
                toast.error(response?.message, { id: 'error' })
            }
        },
        onError: (e) => {
            console.log(e)
            toast.error("Oops! Something went wrong", { id: 'error' })
        }
    })
}