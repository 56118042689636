import React, { useEffect, useState } from 'react'
import Auth from './Auth'
import OtpInput from 'react-otp-input';
import SuccessModal from '../Reusable/SuccessModal2';
import Logo from '../../assets/logo.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom';
import { useSendOtp, useVerifyOtp } from '../../hooks';

const hasOnlyNumber = (str) => /^\d+$/.test(str)

function VerifyOtp() {
    const history = useHistory()
    const [success, setSuccess] = useState(false)
    const [otp, setOtp] = useState('');
    const [timer, setTimer] = useState(0);
    const [showSendAgain, setShowSendAgain] = useState(false);

    useEffect(() => {
        if (!history.location.state?.email || !history.location.state?.type) {
            window.location.replace("/404")
        }
    }, [history.location.state])

    useEffect(() => {
        let interval;

        if (timer > 0) {
            interval = setInterval(() => {
                setTimer(timer - 1);
            }, 1000);
        } else {
            setShowSendAgain(true);
            clearInterval(interval);
        }

        return () => clearInterval(interval); 
    }, [timer]);

    const handleSendAgainClick = () => {
        setTimer(30);
        setShowSendAgain(false); 
    };

    const handleChange = (otp) => {
        setOtp(otp);
    };

    const { mutate: verifyOtp, isLoading } = useVerifyOtp((data) => {
        if (history.location.state?.register) {
            if (history.location.state?.type == 'admin')
                window.location.replace('https://compadmin.aqqess.me')
            else
                history.push("/");
        }
        else {
            history.push("/reset-password", { email: history.location.state?.email, type: history.location.state?.type })
        }

    }
    )

    let { mutate } = useSendOtp()

    const handleSubmit = (e) => {
        e.preventDefault();
        verifyOtp({ email: history.location.state?.email, otp: otp, type: history.location.state?.type })
    }

    const sendAgain = () => {
        mutate({ email: history.location.state?.email, type: history.location.state?.type, flow: 'resend' })
        handleSendAgainClick();
    }

    const renderInput = (inputProps) => (
        <input {...inputProps} className="bg-[#0E0A22] rounded flex-grow h-28 text-4xl" />
    );

    return (
        <Auth>
            {
                history.location.state?.branchName
                    ?
                    <div className='max-w-max absolute top-10 left-5'>
                        <h1 className='text-4xl font-medium text-cyan'>{history.location.state?.branchName}</h1>
                    </div>
                    :
                    <></>
            }
            <form className='w-full flex flex-col items-center gap-3 max-w-xl'>
                <h1 className='text-4xl 2xl:text-5xl font-medium mb-10 text-center'>Verify OTP</h1>
                <OtpInput
                    value={otp}
                    onChange={handleChange}
                    numInputs={6}
                    containerStyle="w-full max-w-xl gap-5"
                    isInputNum={true}
                    renderInput={renderInput}
                />
                <div className='flex flex-col justify-end items-end gap-1 mt-4 w-full'>
                    <button disabled={otp?.length !== 6 || !hasOnlyNumber(otp) || isLoading} onClick={handleSubmit} className='bg-8 py-1.5 px-6 rounded-xl text-lg'>
                        <span>Submit</span>
                    </button>
                    <p>did not receive the email? {showSendAgain ? <span onClick={sendAgain} className='hover:text-yellow-300 cursor-pointer'>Send Again</span> : <span>Wait for {timer}s</span>}</p>
                </div>
            </form>
            <div className='flex items-center justify-center gap-2 py-5 cursor-pointer absolute bottom-5 right-10'>
                <img src={Logo} alt="" />
                <h1 className='text-3xl font-medium'>Aqqess</h1>
            </div>
            {success ? <SuccessModal onClose={() => { setSuccess(false) }} message={success?.message} /> : <></>}
        </Auth>
    )
}

export default VerifyOtp