import React, { useState } from 'react'
import Auth from './Auth'
import Logo from '../../assets/logo.svg'
import { useHistory, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import validator from 'validator'
import { useLogin } from '../../hooks'
import { encryptPassword } from '../../services/constant'
import { selectCompany } from '../../slices/userSlice'
import { useSelector } from 'react-redux'

function Login({ type }) {
  const { branchName } = useParams()
  const company = useSelector(selectCompany)
  const [show, setShow] = useState(false)
  const history = useHistory()
  const [data, setData] = useState({
    email: '',
    password: ''
  })

  const handleChange = (e) => {
    setData({ ...data, [e.target.id]: e.target.value })
  }

  const { mutate: login, isLoading } = useLogin();

  const handleSubmit = (e) => {
    e.preventDefault();
    let { email, password } = data
    login({ email, password: encryptPassword(password), type, subdomain: company?.subdomain })
  }

  return (
    <Auth>
      {branchName ? <div className='max-w-max absolute top-10 left-5'>
        <h1 className='text-4xl font-medium text-cyan'>{branchName}</h1>
      </div> : ''}
      <form className='w-full lg:max-w-md 2xl:max-w-lg flex flex-col gap-3'>
        {type == 'admin' ? <h1 className='text-5xl font-medium mb-10 text-left w-full'>Company Admin</h1> : ''}
        <h1 className='text-3xl 2xl:text-4xl font-medium'>Welcome Back!</h1>
        <input type="email" id="email" value={data.email} onChange={handleChange} minLength={5} placeholder='Enter your email ID' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
        <div className='relative'>
          <input type={show ? 'text' : 'password'} id='password' value={data.password} onChange={handleChange} minLength={5} placeholder='Enter your password' className='py-2 px-4 rounded-xl bg-[#0A0817] text-[#6993FF] w-full text-lg outline-none border-none' />
          {!show ? <svg onClick={() => setShow(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
            <path strokeLinecap="round" strokeLinejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
            <path strokeLinecap="round" strokeLinejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
          </svg>
            : <svg onClick={() => setShow(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="currentColor" className="w-6 h-6 absolute top-2 right-3 cursor-pointer">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
            </svg>}
        </div>

        <p className='text-[#FFA7A7] text-lg underline underline-offset-2 ml-2 cursor-pointer' onClick={() => history.push("/forgot-password", { type, branchName })}>Forgot password?</p>

        <div className='flex flex-col justify-end items-end gap-1 mt-2'>
          <button onClick={handleSubmit} disabled={!validator.isEmail(data.email) || data.password?.length < 6} className='bg-8 py-1.5 px-6 rounded-xl text-lg min-w-[120px] flex items-center justify-center'>
            {
              isLoading
                ?
                <div className='w-7 h-7 rounded-full border-2 border-white border-b-transparent animate-spin'></div>
                : <span>Login</span>
            }
          </button>
          <p>First time? <span className='hover:text-yellow-300 cursor-pointer' onClick={() => history.push(branchName ? `/branch/${branchName}/signup` : `/admin/signup`)}>SignUp now</span></p>
        </div>
      </form>

      <div className='flex items-center justify-center gap-2 py-5 cursor-pointer absolute bottom-5 right-10'>
        <img src={Logo} alt="" />
        <h1 className='text-3xl font-medium'>Aqqess</h1>
      </div>
    </Auth>
  )
}

export default Login