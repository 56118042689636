import { endpoints } from './config';
import HttpService from './axios-interceptor';
import axios from 'axios';

const auth = () => {
  return HttpService.get("/admin" + endpoints.auth);
};

const validateSubDomain = (subDomain) => HttpService.get("/admin" + endpoints.validateSubDomain + `?subdomain=${subDomain}`);

const getSubDomain = (companyName) => HttpService.get("/admin" + endpoints.company + endpoints.subdomain + `?companyName=${companyName}`);

const getCompany = (subdomain) => subdomain ? HttpService.get("/admin" + endpoints.company + `?subdomain=${subdomain}`) : {};
const getCountries = (search) => search ? axios.get(endpoints.url + "/location" + `?search=${search}`) : {};

const sendOtp = data => {
  return HttpService.post(
    "/admin" + endpoints.sendOtp,
    data,
  );
};

const verifyOtp = data => {
  return HttpService.post(
    "/admin" + endpoints.verifyOtp,
    data,
  );
};

const login = data => {
  return HttpService.post(
    "/admin" + endpoints.login,
    data,
  );
};

const register = data => {
  return HttpService.post(
    "/admin" + endpoints.register,
    data,
  );
};

const updateCompany = data => {
  return HttpService.patch(
    "/admin" + endpoints.company,
    data,
  );
};

const updateProfile = data => {
  return HttpService.patch(
    "/admin" + endpoints.profile,
    data,
  );
};

const resetPassword = data => {
  return HttpService.patch(
    "/admin" + endpoints.resetPassword,
    data,
  );
};

const updatePassword = data => {
  return HttpService.patch(
    "/admin" + endpoints.updatePassword,
    data,
  );
};

const getBranches = () => HttpService.get('/admin' + endpoints.branch)
const createBranch = (data) => HttpService.post('/admin' + endpoints.branch, data)
const editBranch = (data) => HttpService.patch('/admin' + endpoints.branch, data)
const deleteBranch = (id) => id ? HttpService.delete('/admin' + endpoints.branch + `?id=${id}`) : {}

const getBranchAdmin = () => HttpService.get('/admin' + endpoints.branchAdmin)
const createBranchAdmin = (data) => HttpService.post('/admin' + endpoints.branchAdmin, data)
const editBranchAdmin = (data) => HttpService.patch('/admin' + endpoints.branchAdmin, data)
const deleteBranchAdmin = (id) => id ? HttpService.delete('/admin' + endpoints.branchAdmin + `?id=${id}`) : {}

export const AdminService = {
  getCompany,
  validateSubDomain,
  getSubDomain,
  auth,
  sendOtp,
  verifyOtp,
  login,
  register,
  updateCompany,
  updateProfile,
  resetPassword,
  updatePassword,
  getCountries,
  getBranches, createBranch, editBranch, deleteBranch,
  getBranchAdmin, createBranchAdmin, editBranchAdmin, deleteBranchAdmin
};