import React, { useRef, useState } from 'react'
import Logo from '../../assets/logo.svg'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { useClickOutside } from '../../services/constant'
import loginBubble from '../../assets/loginBubble.svg'
import OTPInput from 'react-otp-input'
import toast from 'react-hot-toast'
import { useQuery } from 'react-query'
import { AdminService } from '../../services/admin.service'
import { useEffect } from 'react'

const hasNumber = (str) => /\d/.test(str)
const hasOnlyNumber = (str) => /^\d+$/.test(str)

function SubDomain() {
  const [show, setShow] = useState(false)
  const history = useHistory()
  const [error, setError] = useState(false)
  const [errorBox, setErrorBox] = useState(false)
  const [numeric, setNumeric] = useState('');
  const [alpha, setAlpha] = useState('')
  const [branchName, setBranchName] = useState('')
  const [verified, setVerified] = useState(false)
  const ref = useRef()
  useClickOutside(() => setShow(false), ref)

  useEffect(() => {
    let subdomain = window.location.hostname.split(".")[0];
    if (subdomain.includes("-")) {
      let [a, n] = subdomain.split("-")
      setAlpha(a);
      setNumeric(n)
    }
  }, [])

  const { data: valRes, refetch: validateSubDomain } = useQuery('validateSubDomain', () => AdminService.validateSubDomain(`${alpha}-${numeric}`), {
    retry: false,
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: res => {
      if (!res?.data) {
        setErrorBox(true)
        setError(true)
        setVerified(false);
      }
      else {
        setError(false);
        setErrorBox(false);
        setVerified(true);
        toast.success("Company code verified", { id: 'success' })
        // window.location.replace(`http://${alpha}-${numeric}.comp.localhost:3000/admin/login`);
        window.location.replace(`https://${alpha}-${numeric}.compadmin.aqqess.me/admin/login`);
      }
    },
    onError: res => console.log(res)
  })

  const accessPortal = () => {
    if (hasNumber(alpha) || !hasOnlyNumber(numeric)) {
      return toast.error("Please provide a valid url", { id: 'error' });
    }
    else {
      toast('Verifying...', { id: 'success' })
      validateSubDomain()
      // window.location.replace(`https://${alpha}-${numeric}.admin.aqqess.me`)
    }
  }
  const proceedLogin = () => {
    window.location.replace(`https://${alpha}-${numeric}.admin.aqqess.me/branch/${branchName}/login`)
  }

  const renderInput = (inputProps) => (
    <input {...inputProps} className="bg-transparent flex-grow border-b-2 h-10 outline-none text-2xl" />
  );

  return (
    <section className='h-screen w-full flex bg-[#161F3B]'>
      <div className='w-2/5 h-screen bg-[#14121F] grid place-items-center relative'>
        <img src={loginBubble} className='w-full h-full object-cover' alt="" />
      </div>

      <div className='flex-grow flex flex-col items-center justify-center relative p-5 lg:px-10 2xl:px-16 -mt-10'>
        <div className='w-full flex flex-col gap-3'>
          <h1 className='text-[#C8BAFF] font-semibold text-5xl mb-12'>Company Admin Login</h1>
          <div>
            <h1 className='text-2xl xl:text-3xl font-medium'>Enter Your company code</h1>
            <p className='font-light my-1 xl:text-lg'>If you have misplaced ask a colleague, branch admin</p>
            <div className='flex items-center gap-5 my-10' onClick={() => { setErrorBox(false); setVerified(false) }}>
              <div className='w-full max-w-xs'>
                <OTPInput
                  value={alpha}
                  onChange={(txt) => setAlpha(txt?.toLowerCase())}
                  numInputs={4}
                  inputType='text'
                  containerStyle={`w-full max-w-xs gap-5 bg-[#253565] p-3 rounded-lg border-2 ${errorBox ? 'border-[#FF0000]' : 'border-transparent'} `}
                  renderInput={renderInput}
                />
                <p className='font-thin'>Alpha-numeric value</p>
              </div>
              <div className='w-16 h-0.5 mb-5 bg-gray-300 rounded-full'></div>
              <div className='w-full max-w-xs'>
                <OTPInput
                  value={numeric}
                  onChange={(txt) => setNumeric(txt?.toLowerCase())}
                  numInputs={4}
                  containerStyle={`w-full max-w-xs gap-5 bg-[#253565] p-3 rounded-lg border-2 ${errorBox ? 'border-[#FF0000]' : 'border-transparent'} `}
                  isInputNum={true}
                  renderInput={renderInput}
                />
                <p className='font-thin'>Numeric value</p>
              </div>
              <div className='mb-5 text-2xl font-medium'>
                .aqqess.me
              </div>
            </div>
            {error ? <p className='text-[#FF0000] my-2'>Looks like you have entered the wrong code.</p> : <></>}
            <div className='flex justify-end'>
              {
                (alpha?.length !== 4 || numeric?.length !== 4)
                  ?
                  <button className='py-1.5 px-4 rounded-xl bg-[#4A3F78] opacity-70'>Access Portal</button>
                  : <button className='py-1.5 px-4 rounded-xl bg-8' onClick={accessPortal}>Access Portal</button>
              }
            </div>
          </div>


        </div>
        <div className='flex items-center justify-center gap-2 py-5 cursor-pointer absolute bottom-5 right-10'>
          <img src={Logo} alt="" />
          <h1 className='text-3xl font-medium'>Aqqess</h1>
        </div>
      </div>
    </section>
  )
}

export default SubDomain