import React from "react";
import { Route, Redirect } from "react-router-dom";
import Sidebar from "./components/Reusable/Sidebar";
import TopBar from "./components/Reusable/TopBar";
import { decodeToken } from "./services/constant";

const AdminRoute = ({ component: Component, verified, path, title, ...rest }) => {
    const auth = decodeToken()

    return (
        <Route
            {...rest}
            render={(props) =>
                !auth ? (
                    <Redirect to="/admin/login" />
                ) : auth?.type == 'admin' ?
                    (
                        <AdminComponent component={Component} title={title} />
                    )
                    : (
                        <Redirect to="/404" />
                    )
            }
        />
    );
};

export default AdminRoute;

const AdminComponent = ({ component: Component, title: title }) => {

    return (
        <>
            <section className='h-screen w-full flex items-stretch'>
                <Sidebar />
                <div id="main" className='flex-grow overflow-y-auto bg-1' style={{ height: '100dvh' }}>
                    <TopBar title={title} />
                    <Component />
                </div>
            </section>
        </>
    )
}